import React from 'react';
import Template from '@clearscore/shared.website-template';
import PropTypes from 'prop-types';

import styles from './protect-paid-landing.module.css';
import Hero from './components/hero';
import Body from './components/body';

const ProtectPaidLandingPage = ({ appStoreConfig, cookiePolicyConfig, hero }) => (
    <Template appStoreConfig={appStoreConfig} cookiePolicyConfig={cookiePolicyConfig}>
        <div className={styles.protect}>
            <div className={styles.sectionContainer}>
                <Hero hero={hero} />
                <Body />
            </div>
        </div>
    </Template>
);

ProtectPaidLandingPage.propTypes = {
    appStoreConfig: PropTypes.shape({
        googlePlayUrl: PropTypes.string,
        appStoreUrl: PropTypes.string,
        appStoreLink: PropTypes.string,
        playStoreLink: PropTypes.string,
        appleStoreToggle: PropTypes.bool,
        googleStoreToggle: PropTypes.bool,
    }).isRequired,
    cookiePolicyConfig: PropTypes.shape({
        moreInfoLinkUrl: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
    }).isRequired,
    hero: PropTypes.node,
};

ProtectPaidLandingPage.defaultProps = {
    hero: null,
};

export default ProtectPaidLandingPage;
