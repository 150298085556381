import { EN_GB, EN_AU, EN_CA, EN_NZ, EN_ZA } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        section: 'hero',
        title: 'Get round-the-clock identity protection with ClearScore Protect Plus',
        description: [
            'We monitor your credit report and the dark web for signs of identity theft and fraud, give you access to a 24/7 fraud helpline, and provide up to £40,000 identity theft insurance.',
            'Join ClearScore for your free credit score and report, then get Protect Plus for round-the-clock identity protection for £7.99 / month or £59.99 / year (37% discount).',
        ],
        ctaText: 'Get Protect Plus',
        ctaUrl: 'https://app.clearscore.com/protect',
    },
    [EN_ZA]: {
        section: 'hero',
        title: 'Get round-the-clock identity protection ',
        description: [
            'We monitor your credit report and the dark web for signs of identity theft and fraud, give you access to a 24/7 fraud helpline, and provide up to $1 million (USD) identity theft cover.',
        ],
        ctaText: 'Learn more about Protect Plus',
        ctaUrl: 'https://app.clearscore.com/za/protectplus',
    },
    [EN_CA]: {
        section: 'hero',
        title: 'Get round-the-clock identity protection ',
        description: [
            'We monitor your credit report and the dark web for signs of identity theft and fraud, give you access to a 24/7 fraud helpline, and provide up to $1 million (USD) identity theft cover.',
        ],
        ctaText: 'Learn more about Protect Plus',
        ctaUrl: 'https://app.clearscore.com/ca/protectplus',
    },
    [EN_NZ]: {
        section: 'hero',
        title: 'Get round-the-clock identity protection ',
        description: [
            'We monitor your credit report and the dark web for signs of identity theft and fraud, give you access to a 24/7 fraud helpline, and provide up to $1 million (USD) identity theft cover.',
        ],
        ctaText: 'Learn more about Protect Plus',
        ctaUrl: 'https://app.clearscore.com/nz/protectplus',
    },
    [EN_AU]: {
        section: 'hero',
        title: 'Get round-the-clock identity protection ',
        description: [
            'We monitor your credit report and the dark web for signs of identity theft and fraud, give you access to a 24/7 fraud helpline, and provide up to $1 million (USD) identity theft cover.',
        ],
        ctaText: 'Learn more about Protect Plus',
        ctaUrl: 'https://app.clearscore.com/au/protectplus',
    },
};
