import React from 'react';
import PropTypes from 'prop-types';
import Text from '@clearscore/ui.rainbow.text';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import Container from '@clearscore/ui.rainbow.container';
import Spacer from '@clearscore/ui.rainbow.spacer';
import Stack from '@clearscore/ui.rainbow.stack';

import style from './body.module.css';
import lang from './lib/lang';
import Cta from '../cta';

const Snippet = ({ title, description }) => (
    <section>
        <Spacer
            all={{
                vertical: Spacer.spacings.MEDIUM,
            }}
            medium={{ right: Spacer.spacings.MEDIUM }}
        >
            <Stack all={Stack.spacings.MEDIUM}>
                <Text.H3 dataId="snippet-title">{title}</Text.H3>
                <Text.Body1 dataId="snippet-description">{description}</Text.Body1>
            </Stack>
        </Spacer>
    </section>
);

Snippet.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

const Body = () => {
    const language = useLanguage(lang);
    return (
        <article data-qa={language.section}>
            <Container size={Container.Size.HUGE} theme={Container.Theme.LIGHT}>
                <div className={style.body}>
                    <Stack all={Stack.spacings.HUGE}>
                        <div className={style.title}>
                            <Text.H2 dataId="title">{language.title}</Text.H2>
                        </div>

                        <div className={style.snippets}>
                            {language.snippets.map(({ title, description }) => (
                                <Snippet key={title} title={title} description={description} />
                            ))}
                        </div>
                        <Cta language={language} />
                    </Stack>
                </div>
            </Container>
        </article>
    );
};

export default Body;
