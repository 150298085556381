import React from 'react';
import PropTypes from 'prop-types';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import Text from '@clearscore/ui.rainbow.text';
import { GatsbyImage } from 'gatsby-plugin-image';
import Spacer from '@clearscore/ui.rainbow.spacer';
import Stack from '@clearscore/ui.rainbow.stack';
import Container from '@clearscore/ui.rainbow.container';

import styles from './hero.module.css';
import lang from './lib/lang';
import Cta from '../cta';

const Hero = ({ hero }) => {
    const language = useLanguage(lang);
    return (
        <header className={styles.hero} data-qa={language.section}>
            {hero && (
                <div className={styles.backgroundImage}>
                    <GatsbyImage image={hero} alt="Woman entering market" />
                </div>
            )}
            <Container size={Container.Size.HUGE}>
                <div className={styles.heroBox}>
                    <Spacer all={{ vertical: Spacer.spacings.LARGE }} medium={{ horizontal: Spacer.spacings.LARGE }}>
                        <Stack all={Stack.spacings.SMALL} medium={Stack.spacings.BIG}>
                            <div className={styles.title}>
                                <Text.H2 dataId="title" tag={Text.tags.H1}>
                                    {language.title}
                                </Text.H2>
                            </div>
                            <div className={styles.blurb}>
                                <Text.Body1 dataId="description-0">{language.description[0]}</Text.Body1>
                                <Spacer all={{ bottom: Spacer.spacings.SMALL }} />
                                <Text.Body1 dataId="description-1">{language.description[1]}</Text.Body1>
                            </div>
                            <Cta language={language} />
                        </Stack>
                    </Spacer>
                </div>
            </Container>
        </header>
    );
};

Hero.propTypes = {
    hero: PropTypes.node,
};

Hero.defaultProps = {
    hero: null,
};

export default Hero;
