import React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type { ImageDataLike } from 'gatsby-plugin-image';
import { getImage } from 'gatsby-plugin-image';
import ProtectPaid from '@clearscore/website.protect-paid-landing';

import avatar from '../assets/gb/protect/avatar_protect_250x250.jpg';
import Layout from '../components/layout';

const ProtectPaidTemplate = ({ data, location }: PageProps<Queries.ProtectPaidTemplateQuery>): React.ReactElement => {
    const hero = getImage(data.heroImage as ImageDataLike);
    return (
        <Layout
            meta={{
                title: 'ClearScore Protect Plus | Round-the-clock identity protection',
                description:
                    'We use advanced web scanning to locate breaches of your personal data. You also get daily credit report updates and access to a dedicated fraud support manager.',
                image: avatar,
            }}
            location={location.pathname}
        >
            {({ commonModules }): React.ReactElement => (
                <ProtectPaid
                    appStoreConfig={commonModules.app_store}
                    cookiePolicyConfig={commonModules.cookie_policy}
                    hero={hero}
                />
            )}
        </Layout>
    );
};

export const query = graphql`
    query ProtectPaidTemplate {
        heroImage: file(absolutePath: { regex: "/protect/hero.jpg/" }) {
            childImageSharp {
                gatsbyImageData(quality: 80, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
            }
        }
    }
`;

export default ProtectPaidTemplate;
