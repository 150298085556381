import useWesbiteTracking from '@clearscore-group/lib.hooks.use-website-tracking';

const PROTECT_PAID_LANDING_EVENT = 'fe_protect_plus_landing_cta_clicked';

export default () => {
    const track = useWesbiteTracking();

    return {
        trackProtectCtaClick: ({ e, language }) => {
            if (e) e.preventDefault();
            track({
                name: PROTECT_PAID_LANDING_EVENT,
                props: {
                    e: PROTECT_PAID_LANDING_EVENT,
                    module_type: `protect-paid-${language.section}`,
                    cta_copy: language.ctaText,
                    cta_url: language.ctaUrl,
                },
                onComplete: () => {
                    window.location.assign(language.ctaUrl);
                },
            });
        },
    };
};
