import React from 'react';
import PropTypes from 'prop-types';
import Button from '@clearscore/ui.rainbow.button';
import { setPublisherCookie } from '@clearscore-group/lib.helpers.cookies';

import useProtectPaidTracking from '../../lib/hooks/use-tracking';
import useUtmSource from '../../lib/hooks/use-utm-source';

const Cta = ({ language }) => {
    const { trackProtectCtaClick } = useProtectPaidTracking();
    const utmSource = useUtmSource();

    const handleOnClick = (e) => {
        setPublisherCookie(`?utm_source=${utmSource || 'protect_plus_landing'}&utm_campaign=protect&utm_medium=cta`);
        trackProtectCtaClick({ e, language });
    };

    return (
        <Button dataId="cta" size={Button.sizes.LARGE} isResponsive href={language.ctaUrl} onClick={handleOnClick}>
            {language.ctaText}
        </Button>
    );
};

Cta.propTypes = {
    language: PropTypes.shape({
        ctaText: PropTypes.string.isRequired,
        ctaUrl: PropTypes.string.isRequired,
    }).isRequired,
};

export default Cta;
