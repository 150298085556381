import { EN_GB } from '@clearscore-group/lib.config.i18n';

export default {
    [EN_GB]: {
        section: 'body',
        title: 'What you get with Protect Plus',
        snippets: [
            {
                title: 'Daily dark web scanning',
                description:
                    "We'll scan the dark web for your personal information. Find out if your data is exposed and available to others.",
            },
            {
                title: 'Up to £40,000 identity theft insurance',
                description: 'Covers costs associated with identity theft and fraud, such as legal fees.',
            },
            {
                title: 'Daily credit report updates',
                description:
                    'Get your credit report updated every day. Find out about any changes to your score, financial data (such as balances) or fraud-relevant changes straight away.',
            },
            {
                title: '24/7 fraud helpline',
                description:
                    'Get access to experts anytime for help with suspicious messages, identity theft and fraud.',
            },
        ],
        ctaText: 'Get Protect Plus',
        ctaUrl: 'https://app.clearscore.com/protect',
    },
};
